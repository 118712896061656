import { React, useState, useEffect } from "react";

import { Box, Typography, Stack } from "@mui/material";
import axios from "axios";
import FaqContents from "../components/FaqContents";
import img_1 from "../resources/images/faq/faq-img-pc.png";

function FAQ(props) {
  const nowMediaQuery = props.nowMediaQuery;
  const [faq, setFaq] = useState([
    {
      _id: "637321fdd44f50fcf8336260",
      content: "생후 2개월령 이상부터 비문등록이 가능합니다.",
      title: "태어나자마자 비문등록이 가능한가요?",
    },
    {
      _id: "637321fdd44f50fcf833625c",
      title: "비문이 무엇인가요?",
      content:
        "비문은 동물의 코 전반에 분포된 고유한 무늬로, 인간의 지문과 같이 일생동안 변하지 않아 개체 식별 수단으로 사용이 가능합니다.",
    },
    {
      _id: "637321fdd44f50fcf833625f",
      content:
        "애니퍼피는 다양한 이미지 DB 테스트 및 3년간의 필드 테스트를 통해 정확성과 사용자 편의성을 확인하여, 99.99%의 정확도를 보유하고 있습니다.",
      title: "비문인식이 정확한가요?",
    },
    {
      _id: "637321fdd44f50fcf833625d",
      content:
        "동물등록제란, 사람이 주민등록번호를 가지는 것과 같이, 반려동물에게도 고유한 번호를 부여하여 등록하는 제도를 말합니다.\n\n현재 동물등록을 관리하는 농림축산식품부에서 인정되는 동물등록방법은 내장형 칩(RFID)와 외장형 칩(RFID) 두가지로 지정되어 있습니다.\n\n하지만 현재의 동물등록 방식은 칩 이식 부작용에 대한 우려, 분실 문제 등 한계점을 지니고 있습니다.\n\n이 한계점을 뛰어넘고자 애니퍼피는 비문인식을 통한 동물등록을 위해 노력을 기울이고 있습니다.",
      title: "동물등록제가 무엇인가요?",
    },
    {
      _id: "637321fdd44f50fcf8336261",
      content:
        "반려견이 활동적이라면 산책을 충분히 시키신 후 야외에서 촬영하시면 더욱 잘 찍을 수 있습니다.\n\n애니퍼피가 가장 추천드리는 방법은 반려견이 코를 보이며 자고 있을 때 촬영하시면 수월하게 비문등록이 가능합니다.",
      title:
        "저희 반려견은 너무 움직여서 비문촬영이 어려워요. 방법이 있을까요?",
    },
    {
      _id: "637321fdd44f50fcf8336264",
      title:
        "현재 상용화되고 있는 내장 마이크로칩 대비 비문 인식의 장점은 무엇인가요?",
      content:
        "반려동물의 신원 확인 방법으로 마이크로칩 삽입과 스마트폰을 통한 비문인식을 비교해볼 때, 윤리성, 경제성, 사용성, 편리성, 정확성, 직관성 등 모든 측면에서 스마트폰을 통한 비문인식이 우위를 가집니다. \n\n또한 마이크로칩과 반려동물과의 직접적인 상관관계는 없으며, 마이크로칩에 있는 동물등록번호는 위조/변조가 가능합니다. 하지만 비문은 반려동물 고유의 코 무늬로 모든 반려견마다 다르며 비문의 패턴을 위조 변조할 수 없습니다.\n\n마이크로칩은 체내에서 염증을 일으킬 수 있으며, 반려동물이 CT, MRI 검사를 받을 때에는 체내에서 빼내야 하는 불편함이 있습니다. \n따라서 반려동물의 개체식별 수단으로는 가장 반려동물에게 친근한 비문기반 방식이 적합할 것으로 확신합니다.",
    },
    {
      _id: "637321fdd44f50fcf833625e",
      title: "비문등록이 동물등록으로 인정되나요?",
      content:
        "현재 아직까지는 비문등록이 정부의 공식 동물등록 방식은 아닙니다. 그러나 정부에서 동물등록율을 높이고 펫 보험을 활성화하기 위해 비문을 동물등록 방식으로 채택하기 위해 모두가 노력을 기울이고 있습니다.",
    },
    {
      _id: "637321fdd44f50fcf8336265",
      title:
        "비문 등록 서비스가 상용화되면 구체적으로 기술이 어떻게 적용될 수 있나요?",
      content:
        "anipuppy에 비문이 등록된다면 향후 비문으로 반려견을 잃어버려도 쉽게 찾을 수 있는 서비스를 제공할 예정입니다. \nanipuppy앱을 보유한 사용자 혹은 구조자가 유기, 유실된 반려견 비문을 찍으면 주인에게 연락을 취하는 방식으로 제공될 것입니다.\n\n 또한, 유기 유실 예방뿐만 아니라 반려동물의 개체별 비문 ID가 있다면 의료, 보험, 헬스케어, 미용, 호텔링, 도크워킹, 신탁(증여/상속) 등 반려동물 업계가 ID기반으로 개체 이력관리를 할 수 있고, 개체별로 입양부터 장묘까지 모든 생애관리가 가능합니다. \n\n이로 인해 반려동물의 복지가 향상되고 산업이 활성화되는 것을 기대합니다.",
    },
    {
      _id: "637321fdd44f50fcf8336262",
      title:
        "비문등록을 하고 난 뒤에 비문조회를 해봤는데, 저희 반려견의 정보가 안나와요.",
      content:
        "비문등록이 완료되었지만 조회가 되지 않거나 다른 반려견의 비문이 함께 등록된 경우, 이는 등록된 비문 이미지의 품질이 좋지 않기 때문입니다. 좋지 않은 품질의 비문 이미지로는 다음과 같은 것들이 있습니다.\n\n1. 흔들린 이미지\n2. 코에 수분이 많아 빛 반사가 있는 경우\n3. 비문의 패턴이 뚜렷하지 않은 경우\n4. 코에 이물질(콧물, 침, 털)이 있는 경우\n5. 카메라가 깨끗하지 않아 흐린 이미지\n\n위 항목들을 고려하여 다시 비문을 등록하고 조회해 보시기 바랍니다. 여전히 문제가 해결되지 않는다면 문의글을 남겨주시기 바랍니다.",
    },
    {
      _id: "637321fdd44f50fcf8336266",
      title:
        "사람의 지문이 닳는 것처럼 강아지 비문도 닳아서 인식이 안될 가능성은 없나요?",
      content:
        "애니퍼피의 비문 연구진과 건국대 동물병원과 함께 비문의 불변성 관련 연구를 진행하였습니다.\n\n동물병원에 내원하는 다양한 연령대의 반려견을 대상으로 일정기간마다 정기적으로 여러 차례에 걸쳐 비문을 추적 조사한 결과 비문이 변하지 않는 사실을 검증하였고 관련 내용은 수의학 전문 저널인 스위스 MDPI Animal에 기재되었고, 대한 수의학회 학술대회에서도 발표되었습니다.\n연구결과에 따라 반려견의 연령에 따른 비문이 변하지 않는다는 사실은 입증이 되었습니다. 하지만 반려견의 코에 상처가 나는 일 또는 나이가 들거나 건강상태가 좋아지지 않으면 반려견의 코가 건조해지거나, 색상이 변하거나 하는 등의 비문 패턴에 변형이 생길 가능성이 있습니다.\n따라서 일정 주기로 반려견의 비문을 추가 촬영하여 비문 이미지를 갱신한다면 인식률이 더 정확해질 수 있습니다.\n\n현재 애니퍼피에서도 비문 갱신 기능에 대해 업데이트 준비중입니다. 많은 관심 부탁드립니다 :)",
    },
  ]);

  useEffect(() => {
    axios
      .get("/api/faq")
      .then((response) => {
        typeof response.data === "object" && setFaq(response.data);
      })
      .catch((error) => {});
  }, []);

  const faqList = faq.map((data, idx) =>
    idx !== faq.length - 1 ? (
      <FaqContents
        key={idx}
        question={data.title}
        answer={data.content}
        isLast={false}
      />
    ) : (
      <FaqContents
        key={idx}
        question={data.title}
        answer={data.content}
        isLast={true}
      />
    )
  );

  return (
    <div className={(props.nowMediaQuery, "FAQ")}>
      <section className="front faq">
        <Box sx={{ position: "absolute", top: "20%", zIndex: 1 }}>
          <Typography
            variant="h3"
            className="slogan"
            color="black"
            textAlign="center"
          >
            <strong>
              궁금한 점을
              <br />
              빠르고 간편하게
              <br />
              해결하세요
            </strong>
          </Typography>

          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mt: 2 }}
            color="black"
          >
            애니퍼피에서 자주 묻는 질문을 확인하세요
          </Typography>
        </Box>
        {nowMediaQuery !== "mobile" ? (
          <Stack
            direction={"column"}
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
            }}
          >
            <Box width="35rem">
              <img className="sub-main-img" src={img_1} alt="img" />
            </Box>
          </Stack>
        ) : (
          <Box
            width="80vw"
            maxWidth={"30rem"}
            sx={{
              display: "flex",
              alignSelf: "end",
              marginBottom: 11,
            }}
          >
            <img className="sub-main-img" src={img_1} alt="img" />
          </Box>
        )}
      </section>

      <section className="faq_contents_section">
        <div>
          <Typography variant="h3" className="slogan" color="black">
            자주 묻는 질문
          </Typography>
          <p className="dividing_line"></p>
          <div className="faq_list">{faqList}</div>
        </div>
      </section>
    </div>
  );
}

export default FAQ;
